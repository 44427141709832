const ourservice = [
    {
        'title': 'Bookkeeping',
        'description': 'We handle the entire bookkeeping activity of our clients so that they can escape all the challenges regarding Accounting and can focus on their core business activity'
    },
    {
        'title': 'Compliance',
        'description': `We take care of our clients compliance requirements\n  
        (like GST, TDS filings etc...) so that they stay as a proud compliant company in the society`
    },
    {
        'title': 'Payroll',
        'description': 'We manage payroll process which involves employee tax calculations, issuance of monthly salary slips, management of timesheets and issuing form 16'
    },
    {
        'title': 'Advisory',
        'description': 'We offer advisory services in the fields of direct tax, indirect tax and Company law matters'
    },
    {
        'title': 'ERP Implementation',
        'description': 'We ensure that our clients effectively implement ERP system and achieve the desired management objective'
    },
    {
        'title': 'Incorporation Services',
        'description': 'We provide incorporation services of all tyes of Companies, LLPs and Partnership firms'
    },
    {
        'title': 'Fixed Assets Management',
        'description': 'We maintain the fixed assets register containing full particulars as per the statutory norms. Further we also assist in the physical verification of fixed assets to our clients'
    },
    {
        'title': 'Virtual CFO services',
        'description': 'We oversee business planning and report on financial activities. We understand our clients financial goals / objectives and accordingly assist in fulfilling them'
    }
]

export default ourservice