import React from 'react'
import '../assets/styles/about.css'
import bulletImg from "../assets/images/bullet.png"

const About = () => {
    return (
        <section role='AboutUs' id='about'>
            <h1>About Us</h1>
            <div className="about-content">
                <div className="about-content-points">
                    <img src={bulletImg} alt="bullets" className="bullet-img" />
                    <p>
                        Nikhara Bookkeeping is a new age company that thrives for accuracy as the
                        name suggests<br></br> (in Sanskrit Nikhara means accurate) with an intent to deliver
                        high quality solutions in the fields of Finance, Accounting and HR. We are
                        group of young people driven by passion to resolve the challenges faced by
                        our clients and ultimately add mutual value to our businesses.
                    </p>
                </div>
                <div className="about-content-points">
                    <img src={bulletImg} alt="bullets" className="bullet-img" />
                    <p>
                        Outsourcing a non core activity will help to focus on the main objective of
                        any business and hence increases productivity by reducing administrative
                        works.
                    </p>
                </div>
                <div className="about-content-points">
                    <img src={bulletImg} alt="bullets" className="bullet-img" />
                    <p>
                        Our methodology is technology driven and hence providing a hassle free
                        experience. The management team with over 10 years of experience
                        specialise in the fields of Finance and accounting and hence provide
                        optimum service to our clients
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About