const whyUs = [
    {
        'description': 'Efficient and hassle free accounting with four eye checking concept'
    },
    {
        'description': 'Tailor made MIS reports for your business'
    },
    {
        'description': 'Technology driven process which saves you time and effort for year end closing'
    },
    {
        'description': 'Experienced team at your availability with defined turnaround time'
    }
]


export default whyUs