import React, { useEffect } from 'react'
import NavBar from './components/NavBar';
import Contact from './pages/Contact';
import Home from './pages/Home'
import './App.css';
import './assets/styles/custom.css'
import About from './pages/About';
import OurServices from './pages/OurSrvices';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OutSource from './pages/OutSource';
import Footer from './components/Footer';


function App () {

  useEffect(() => {
    document.title = `Nikhara`
  }, [])

  return (
    <div className="App">
      <NavBar />
      <Home />
      <About />
      <OurServices />
      <OutSource />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
