import React, { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import '../assets/styles/contact.css'
// import mailImg from "../assets/images/mail9.png"
import { motion, useAnimation } from "framer-motion"
import mailImg from '../assets/images/contact.png'
import msgImg from '../assets/images/msg-img.png'
import rocketImg from '../assets/images/rocket-img.png'
import Footer from '../components/Footer'

// import MessageNotification from '../components/MessageNotification'

const Contact = () => {

    const [mailStatus, setMailStatus] = useState('')

    function sendEmail (event) {
        event.preventDefault()
        // console.log(event.target[0].value)
        let name = event.target[0].value
        let email = event.target[1].value
        let message = event.target[2].value

        if (name === '' || email === '' || message === '') {
            setMailStatus('invalidParams')
            setTimeout(() => {
                setMailStatus('')
            }, 5000);
            return
        }
        emailjs.sendForm('service_i4qswk5', 'template_gab3r2j', event.target, 'm3QsBFUa7ZNdJe_dQ')
            .then((result) => {
                // console.log(result.text);
                setMailStatus('success')
                event.target.reset()
            }, (error) => {
                setMailStatus('success')
                // console.log(error.text);
            }).catch((err) => {
                setMailStatus('failure')
            }).finally(() => {
                setTimeout(() => {
                    setMailStatus('')
                }, 5000);
            });


        // console.log("Message Submit")
    }



    return (
        <motion.section role='contact' id='contact'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <img src={rocketImg} alt="small-message-image" className="sm-msg-img" />
            {/* {mailStatus === 'success' && <MessageNotification color="green">Email Sent Successfully</MessageNotification >} */}
            {/* {mailStatus === 'failure' && <MessageNotification color="red">Failed</MessageNotification>} */}
            <div className="image-wrapper">
                <img src={mailImg} alt='Mail US' className='contact-main-img'></img>
                {/* <h1>I fabricate your <span glow-normal="">Imagination</span> into <span glow-normal="">Web</span>.</h1> */}
            </div>
            <div className='contact'>
                <motion.h1
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >Contact</motion.h1>
                <form onSubmit={sendEmail}>
                    <input type='text' name='name' placeholder='Name' />
                    <input type='email' name='sender_email' placeholder='E-Mail' />
                    {/* <input type='text' placeholder='Name' /> */}
                    <textarea rows="6" name='message' placeholder='Message' />
                    <div>
                        <div className="button-wrapper animated-button">
                            <button >Send</button>
                        </div>
                        {mailStatus === 'success' && <div className='success'>Mail Sent</div>}
                        {mailStatus === 'faliure' && <div className='failure'>Mail failed</div>}
                        {mailStatus === 'invalidParams' && <div className='failure'>Invalid Values</div>}

                    </div>
                    {/* <input type='text' placeholder='Name' /> */}
                </form>

            </div>
        </motion.section>
    )
}

export default Contact