import React from 'react'
import '../assets/styles/home.css'
import homeImage from "../assets/images/home.png"
import bookIimg from "../assets/images/book.png"
import brandLogo from "../assets/images/logo13.png"

const home = () => {
    return (
        <section role="home" id="home">
            <img src={bookIimg} alt="" className="sm-book-img" />
            <div className="home-container">
                <div className="home-main-content">
                    <div className="home-brand">
                        <img src={brandLogo} alt="home-logo" className="home-brand-img" />
                        <h1>Nikhara</h1>
                    </div>
                    <h2>Business Solutions</h2>
                    <h4>Hassle free Accounting for everyone</h4>
                </div>
                <div className="home-img-container">
                    <img src={homeImage} alt="home-image" className="home-img" />
                </div>
            </div>
        </section>
    )
}

export default home