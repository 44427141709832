import React from 'react'
import '../assets/styles/footer.css'
import brandLogo from '../assets/images/logo13.png'

const Footer = () => {
    return (
        <footer>
            <div className="footer-brand-container">
                <h2>Nikhara</h2>
                <img src={brandLogo} alt="" className="footer-brand-img" />
            </div>
            <div className='company-details'>
                <h3>Official Info</h3>
                <div className='detail'>
                    <h5>Address:</h5><span>No. 32/1, 1<sup>st</sup> Floor, Rangarao Road, Shankarpuram, Bangalore - 560004</span>
                </div>
            </div>

        </footer>
    )
}

export default Footer