import React, { useDebugValue } from 'react'
import '../assets/styles/ourservices.css'
import ourservices from '../data/ourServices'
import Slider from "react-slick";

const OurServices = () => {
    const settings = {
        dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    };
    return (
        <section role='works' id='ourservices'>
            <h1>Our Services</h1>
            {/* <div className="works-container"> */}
            <Slider {...settings}>
                {/* {[1, 2, 3, 4].map((data, index) => (
                <div>{data}</div>
            ))} */}
                {ourservices.map((data, index) => (
                    <div className="ourwork-card" key={index}>
                        <h3>{data.title}</h3>
                        <p>{data.description}</p>
                    </div>
                ))}
            </Slider>
            {/* </div> */}
        </section>
    )
}

export default OurServices