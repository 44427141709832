import React from 'react'
import '../assets/styles/outSource.css'
import outSourceData from '../data/outSource.js'
import whyUs from '../data/why-us'
import bulletImg from '../assets/images/bullet.png'

const OutSource = () => {
    return (
        <>
            <section id="outsource" role="outsource">
                <h1>Why Outsource ?</h1>
                <div className="outsource-container">
                    {outSourceData.map((data, index) => (
                        <div className="outsource-content" key={index}>
                            <img src={bulletImg} alt="." className="bullet-img" />
                            <p>{data.description}</p>
                        </div>
                    ))}
                </div>
            </section>
            <WhyUs />
        </>
    )
}


const WhyUs = () => {
    return (
        <section role='why-us' id='why-us'>
            <h1>Why choose Nikhara ?</h1>
            <div className="outsource-container">
                {whyUs.map((data, index) => (
                    <div className="outsource-content" key={index}>
                        <img src={bulletImg} alt="." className="bullet-img" />
                        <p>{data.description}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}


export default OutSource