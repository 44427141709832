import React, { useState, useEffect } from 'react'
import '../assets/styles/navbar.css'
import logo from '../assets/images/logo13.png'

const NavBar = () => {

    const pages = [
        {
            "page": "Home",
            'id': '#home'
        },
        {
            "page": "About",
            'id': '#about'
        },
        {
            "page": "Our Services",
            'id': '#ourservices'
        },
        {
            "page": "Why Outsource",
            'id': '#outsource'
        },
        {
            "page": "Contact",
            'id': '#contact'
        }]


    const [selectedPage, setSelectedPage] = React.useState(0)
    // const [dialogisOpen, setDialog] = React.useState(false)
    const [location, setLocation] = React.useState('#home')



    const handleClick = (index, id) => {
        setSelectedPage(index);
        setLocation(id)
    }

    useEffect(() => {
        window.location.href = location
    }, [location])


    return (
        <header>
            <div className="header-logo">
                <img src={logo} alt="logo" className="logo" />
                <h3>Nikhara</h3>
            </div>

            <input type="checkbox" id='nav-toggle' className='nav-toggle' />
            <label htmlFor='nav-toggle' className='nav-toggle-icon '>
                <i className="fa-solid fa-bars"></i>
            </label>
            <nav>
                <div className="nav-items" id="nav-items">
                    {pages.map(({ page, id }, index) => (
                        <div className="item" key={id}>
                            <a href={id} title={page} className={selectedPage === index ? 'active' : ''} onClick={() => {
                                handleClick(index, id);
                                // handleDrawerToggle()
                            }}>{page}</a>
                        </div>
                    ))}

                </div>
            </nav>
        </header>
    )
}

export default NavBar
