const outSourceData = [
    {
        'description': 'Increase focus on your core business activity'
    },
    {
        'description': 'You can demand and achieve accuracy in outsourced activities since it is handled by highly trained teams'
    },
    {
        'description': 'Get scheduled MIS reports and take informed decisions to grow your business'
    },
    {
        'description': 'Saves cost and relieve from hassle of maintenance of accounts'
    }
]

export default outSourceData